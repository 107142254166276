@import "../../../../../../assets/styles/helpers.scss";

.element {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  @include laptop {
    flex-direction: column;
    padding: 20px;
  }
  @include tablet {
    padding: 16px 0;
  }
}
.image {
  width: 34px;
  height: 31px;
}

.content {
  margin-left: 20px;
  @include laptop {
    margin-left: 0;
    margin-top: 16px;
  }
  h4 {
    //Size, Weight, Line Height, Transform
    @include _text(16px, 600, 24px, "uppercase");
    margin-bottom: 20px;
  }
  p {
    @include _text(14px, 500, 20px, none, $text);
  }
}
