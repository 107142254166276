@import "../../assets/styles/helpers.scss";

.sectionTitle {
  span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: $gold;
    text-transform: uppercase;
    @include phone {
      font-size: 12px;
      line-height: 20px;
    }
  }

  h4 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 400;
    color: var(--main-color);
    margin-top: 8px;
  }
}
