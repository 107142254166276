@import "../../../../../assets/styles/helpers.scss";

.element {
  background-color: $blue;
  padding: 15px;
  gap: 46px;
  padding: 15px 20px 15px 15px;
  &:nth-child(n + 2) {
    background-color: $gold;
  }
  width: calc(50% - 14px);

  @include minRange(1100px) {
    display: flex;
  }

  @include tablet {
    width: 100%;
  }
  @include laptop {
    padding: 10px 10px 15px 10px;
    gap: 21px;
  }
  @include phone {
    display: flex;
    flex-direction: column;
  }
}

.leftSide {
  flex-basis: 269px;
  background-color: var(--main-background-header);
  background-image: url("../../../../../assets/images/firstlayer.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  position: relative;
  height: 327px;
  &.hasDarkMode {
    background-image: url("../../../../../assets/images/darkmode/vericalRecDarkMode.png");
  }
  img {
    width: 100%;
    object-fit: contain;
    object-position: bottom;
    height: 327px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
}

.rightSide {
  flex-basis: calc(100% - 269px);
  h4 {
    //Size, Weight, Line Height, Transform
    @include _text(24px, 500, 28px, none, var(--white));
    margin-top: 12px;
    margin-bottom: 8px;
    @include laptop {
      //Size, Weight, Line Height, Transform, Color
      @include _text(18px, 600, 32px, none, var(--white));
    }
  }
  p {
    //Size, Weight, Line Height
    @include _text(16px, 500, 24px, none, var(--white));
    @include Urbanist;
    @include laptop {
      @include _text(14px, 500, 20px, none, var(--white));
    }
  }
}
