@import "../../assets/styles/helpers.scss";

.element {
  height: 60px;
  border: 1px solid $borderPrimary;
  @include center(space-between, center);
  padding: 4px;
  background-color: var(--white);
  cursor: pointer;
  figure {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
    }
  }
}

.title {
  width: calc(100% - 51px);
  height: 100%;
  @include center;
  span {
    @include Urbanist;
    font-size: 12px;
    color: $black;
    line-height: 20px;
    font-weight: 500;
  }
}

.image {
  padding: 6px;
  border-radius: 2px;
  width: 51px;
  height: 100%;
  background-color: var(--white);
  @include center;
}

.modalWrapper {
  width: 274px;
  border-radius: 2px;
  padding: 32px 28px 28px 28px;
  h4 {
    @include _text(14px, 500, 20px);
    @include Urbanist;
    width: 90%;
  }
  p {
    @include Urbanist;
    text-align: center;
    margin: 16px 0;
  }
}

.modalImage {
  background-color: white;
  padding: 18px 15px;
  border-radius: 2px;
  margin-top: 16px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.12);
}

.closeModal {
  cursor: pointer;
  float: right;
}

.downloadImage {
  @include center;
  figure {
    width: 100%;
    img {
      width: 100%;
    }
  }
}

.disabled {
  opacity: 0.3;
}
