@import "../../../../assets/styles/helpers.scss";

.element {
  padding-top: 80px;
  padding-bottom: 120px;
  background-color: var(--seperator-bg);
  @include phone {
    padding: 0;
  }
}

.wrapper {
  background-image: url("../../../../assets/images/firstlayer.png");
  background-color: var(--main-background-header);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  height: 361px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  border-radius: 2px;
  &.hasDarkMode {
    background-image: url("../../../../assets/images/darkmode/communityDarkMode.png");
    @include tablet {
      background-image: url("../../../../assets/images/darkmode/communityMobile.png");
      background-position: bottom right;
    }
  }
  @include laptop {
    height: auto;
    background-size: cover;
  }
  @include tablet {
    flex-wrap: wrap;
    gap: 60px;
  }
  max-width: 1440px;
  margin: 0 auto;
}

.content {
  h4 {
    //Size, Weight, Line Height
    @include _text(32px, 400, 38px);
    color: var(--main-color);
    margin-bottom: 24px;
  }
  span {
    color: $gold;
  }
  p {
    @include _text(18px, 500, 32px, none, $text);
    margin-bottom: 40px;
    @include Urbanist;
    width: 60%;
    @include laptop {
      @include _text(14px, 500, 20px, none, $text);
      width: auto;
    }
  }
}
.buttons {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  button:nth-child(0) {
    color: var(--button-text) !important;
    &:hover {
      color: var(--button-hover-text);
      border-color: var(--button-border);
    }
  }
  a {
    @include phone {
      width: 100%;
    }
  }
  button {
    @include phone {
      width: 100%;
    }
  }
}
.image {
  width: 40%;
  height: 510px;
  object-fit: contain;
  @include tablet {
    width: 100%;
    height: 100%;
  }
}
