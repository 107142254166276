@import "../../../../assets/styles/helpers.scss";

.element {
  background-color: var(--main-background-header);
  margin-bottom: 20px;
  @include tablet {
    margin-bottom: 140px;
    margin-top: 100px;
  }
  @include phone {
    margin-bottom: 80px;
    margin-top: 60px;
    padding-top: 20px;
  }
}

.content {
  display: flex;
  gap: 65px;
  @include desktop {
    min-height: 486px;
  }
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  @include desktop {
    flex-direction: row;
  }
  padding: 80px 0 120px 0;
  @include tablet {
    padding-top: 0;
  }
  @include phone {
    padding-bottom: 60px;
  }
}

.title {
  //Size, Weight, Line Height
  @include _text(16px, 500, 24px, uppercase, $gold);
  @include phone {
    @include _text(14px, 500, 20px, uppercase, $gold);
  }
}

.contentDescription {
  width: 100%;
  @include desktop {
    min-height: 486px;
  }
  @include desktop {
    width: 50%;
  }
}

.imageSide {
  width: 100%;
  @include desktop {
    width: 50%;
  }
}

.contentSlider {
  @include desktop {
    min-height: 563px !important;
  }
  h4 {
    //Size, Weight, LineHeight
    @include _text(56px, 500, 62px, uppercase);
    color: var(--main-color);
    margin-top: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include phone {
      //Size, Weight, Line Height, Transform
      @include _text(25px, 400, 38px, uppercase);
      color: var(--main-color) !important;
    }
  }
  p {
    //Size, Weight, LineHeight, Transform, Color
    @include _text(18px, 500, 32px, none, $text);
    margin-top: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include Urbanist;
    @include phone {
      -webkit-line-clamp: 1;
      //Size, Weight, Line Height
      @include _text(16px, 500, 24px, none, $text);
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
  button {
    margin-top: 24px;
    color: var(--white);
    &:hover {
      color: var(--button-hover-text);
      border-color: var(--button-border);
    }
  }
}

.contentImageSlide {
  height: 563px !important;

  figure {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.swiperSlideWrapper {
  height: 678px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contentImage {
  width: 100%;
  @include desktop {
    min-height: 486px;
  }
  @include desktop {
    width: 50%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.imageMobile {
  width: 100%;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
