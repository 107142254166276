@import "../../../../assets/styles/helpers.scss";

.element {
  margin-top: 120px;
  padding-top: 120px;
  padding-bottom: 40px;
  background-color: $bg;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.title {
  text-align: center;
  padding-bottom: 60px;
}

.video {
  width: 100%;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.play {
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid white;
  @include center;
  position: absolute;
  width: 200px;
  height: 200px;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  button {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
  }
}

.hide {
  display: none;
}
.title {
  text-transform: uppercase;
  h4 {
    //Size, Weight, LineHeight
    @include _text(56px, 500, 62px, uppercase);
    @include tablet {
      //Size, Weight, Line Height, Transform
      @include _text(32px, 400, 38px, uppercase);
    }
  }
}
