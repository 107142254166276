@import "../../../../assets/styles/helpers.scss";

.element {
  background-color: var(--main-background-header);
  background-repeat: no-repeat;
  background-position: top right;
  background-image: url("../../../../assets/images/firstSectionLight.png");
  background-size: contain;
  padding-top: 100px;
  &.hasDarkMode {
    background-image: url("../../../../assets/images/darkmode/firstSectionDarkMode.png");
  }
  @include laptop {
    padding-top: 0;
  }
  @include laptop {
    background-size: cover;
    padding-top: 40px;
  }
  @include phone {
    background-position: top;
    padding: 0;
  }
}

.gridWrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @include phone {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, minmax(337px, min-content));
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}
.heading {
  grid-area: 1 / 1 / 3 / 6;
  @include laptop {
    grid-area: 1 / 1 / 2 / 9;
  }
  @include tablet {
    grid-area: 1 / 1 / 1 / 9;
  }
  @include phone {
    grid-area: 1 / 1 / 2 / 9;
    padding: 20px 20px 0 20px;
  }
}
.contents {
  grid-area: 3 / 1 / 5 / 6;
  @include laptop {
    grid-area: 2 / 1 / 5 / 4;
  }
  @include tablet {
    display: none;
  }
}
.figure {
  grid-area: 1 / 6 / 5 / 9;
  @include laptop {
    grid-area: 2 / 4 / 5 / 9;
  }
  @include tablet {
    grid-area: 2 / 1 / 5 / 9;
  }
  @include phone {
    grid-area: 2 / 1 / 3 / 9;
    padding: 0 20px;
  }
}

.wrapper {
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.contentWrapper {
  flex-basis: 60%;
  @include laptop {
    margin-top: 80px;
    flex-basis: 50%;
  }
  @include tablet {
    width: 100%;
  }
  @include phone {
    padding: 0 1.5625rem;
  }
}
.visualWrapper {
  display: flex;
}

.content {
  h4 {
    //Size, Weight, LineHeight
    @include _text(56px, 500, 62px, uppercase);
    color: var(--main-color);
    margin-bottom: 40px;
    @include phone {
      font-size: 34px;
    }
    @include tablet {
      font-size: 34px;
      width: 100%;
    }
    span {
      color: $gold;
    }
  }
}

.contentItems {
  margin: 30px 0;
  @include tablet {
    display: none;
  }
}

.contentListWrapper {
  border: 1px solid var(--main-background);
  background-color: var(--main-background-header);
  padding: 20px 0 24px 0;
  display: flex;
  @include laptop {
    flex-direction: column;
    padding: 0;
    width: 60%;
  }
  h4 {
    color: var(--main-color) !important;
  }
  & > div {
    &:first-child {
      position: relative;
      &::after {
        content: "";
        background-color: var(--input-notification-border-color);
        position: absolute;
        height: 100%;
        width: 1px;
        right: 0;
        @include laptop {
          width: 50%;
          height: 1px;
          bottom: 0;
          left: 20px;
        }
      }
    }
    &:last-child {
      position: relative;
      &::after {
        content: "";
        background-color: var(--input-notification-border-color);
        position: absolute;
        height: 100%;
        width: 1px;
        left: 0;
        @include laptop {
          width: 50%;
          height: 1px;
          top: 0;
          left: 20px;
        }
      }
    }
  }
}

.image {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.download {
  background: var(--main-background-header);
  border: 2px solid var(--main-background);
  backdrop-filter: blur(44px);

  @include desktop {
    height: 108px;
  }
  @include laptop {
    height: 148px;
  }
  height: 150px;
  position: absolute;
  bottom: 0;
  width: 100%;
  @include center(space-between, center);
  padding: 0 32px;
  @include tablet {
    height: 180px;
    flex-direction: column;
    padding: 40px 24px;
  }
  @include phone {
    bottom: -151px;
  }
}

.downloadDesc {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @include desktop {
    flex-direction: row;
    align-items: center;
  }
  h4 {
    //Size, Weight, LineHeight
    @include _text(24px, 500, 28px);
    @include tablet {
      //Size, Weight, Line Height
      @include _text(18px, 600, 32px);
    }
    color: var(--main-color) !important;
    span {
      color: $blue;
    }
    @include phone {
      //Size, Weight, LineHeight
      @include _text(24px, 500, 28px);
    }
  }

  p {
    @include _text(14px, 500, 20px, none, $text);
    color: var(--main-color);
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include desktop {
      padding: 0 10px;
      margin-left: 50px;
    }
  }
}

.qrcode {
  display: flex;
  align-items: center;
  margin-left: 8px;
  span {
    margin: 0 21px;
    @include Urbanist;
    @include tablet {
      margin: 0 8px;
    }
  }
  @include tablet {
    margin-top: 20px;
    justify-content: space-between;
  }
}
