@import "../../../../assets/styles/helpers.scss";

.element {
  background-color: var(--main-background-header);
  padding-bottom: 40px;
  display: flex;
  gap: 65px;
  @include tablet {
    flex-direction: column;
  }
  max-width: 1440px;
  margin: 0 auto;
  @include maxWidth {
    padding-left: 0;
    padding-right: 0;
  }
  @include phone {
    padding-top: 0;
  }
}

.rightSide {
  width: 50%;
  background-color: var(--main-background);
  padding: 40px;
  @include tablet {
    width: 100%;
  }
  h4 {
    //Size, Weight, Line Height
    @include _text(32px, 400, 38px);
    color: var(--main-color);
    margin-bottom: 12px;
  }
  p {
    //Size, Weight, Line Height
    @include _text(16px, 500, 24px, none, $text);
    @include Urbanist;
    margin-bottom: 58px;
  }
}

.leftSide {
  width: 50%;
  @include tablet {
    width: 100%;
  }
  button {
    color: var(--white);
    &:hover {
      color: var(--button-hover-text);
      border-color: var(--button-border);
    }
  }
}

.title {
  //Size, Weight, Line Height, Transform, Color
  @include _text(16px, 600, 24px, uppercase, $gold);
}

.description {
  //Size, Weight, LineHeight
  @include _text(56px, 500, 62px, uppercase);
  color: var(--main-color);
  margin: 8px 0 32px 0;
  @include laptop {
    //Size, Weight, Line Height, Transform
    @include _text(32px, 400, 38px, uppercase);
    color: var(--main-color);
    margin: 8px 0 32px 0;
  }
  span {
    color: $gold;
  }
}

.text {
  //Size, Weight, LineHeight, Transform, Color
  @include _text(18px, 500, 32px, none, $text);
  @include Urbanist;
  margin-bottom: 40px;
  @include laptop {
    @include _text(14px, 500, 20px, none, $text);
  }
}

.image {
  background-color: var(--main-background-header);
  background-image: url("../../../../assets/images/firstlayer.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  height: 327px;
  &.hasDarkMode {
    background-image: url("../../../../assets/images/darkmode/horizontalDarkMode.png");
  }
  @include laptop {
    height: auto;
  }
  figure {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.disable {
  background: $borderPrimary;
  color: $text;
  border: none;
}
