@import "../../../../assets/styles/helpers.scss";

.element {
  padding-bottom: 120px;
  max-width: 1440px;
  margin: 0 auto;
  @include maxWidth {
    padding: 0;
  }
  @include phone {
    padding-bottom: 60px;
  }
}

.title {
  //Size, Weight, Line Height, Transform, Color
  @include _text(16px, 600, 24px, uppercase, $gold);
  @include tablet {
    //Size, Weight, Line Height, Transform, Color
    @include _text(12px, 500, 20px, uppercase, $gold);
  }
}

.description {
  //Size, Weight, LineHeight
  @include _text(56px, 500, 62px, uppercase);
  margin: 8px 0 76px 0;
  span {
    color: $gold;
  }
  @include tablet {
    //Size, Weight, Line Height, Transform
    @include _text(32px, 400, 38px, uppercase);
    margin: 8px 0 40px 0;
  }
}

.content {
  display: flex;
  gap: 28px;
  @include laptop {
    flex-direction: column;
    gap: 40px;
  }
  min-height: 500px;
}

.contentImage {
  position: relative;
  width: 60%;
  background-color: $gold;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 70%;
  }
  @include laptop {
    width: 100%;
  }
}

.contentDescription {
  background-image: url("../../../../assets/images/firstlayer.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  width: 40%;
  padding: 40px;
  border: 3px solid $borderSecondary;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include laptop {
    width: 100%;
  }
  @include tablet {
    padding: 16px;
  }
}

.contentSlider {
  height: 260px;
}

.contentSliderSlide {
  h4 {
    //Size, Weight, LineHeight
    @include _text(24px, 500, 28px);
    margin-bottom: 34px;
  }
  p {
    //Size, Weight, LineHeight, Transform, Color
    @include _text(18px, 500, 32px, none, $text);
    @include Urbanist;
  }
}

.contentDescQrcode {
  h4 {
    //Size, Weight, Line Height
    @include _text(18px, 600, 32px);
    margin-bottom: 8px;

    span {
      color: $blue;
    }
  }
}

.contentDescQrcodeQr {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 400px) {
    flex-direction: column;
    gap: 10px;
  }
  span {
    margin: 0 21px;
    @include Urbanist;
  }
}
