@import "../../../../../assets/styles/helpers.scss";

.element {
  height: 94px;
  background-color: white;
  border-radius: 2px;
  padding: 8px 56px 8px 8px;
  @include center(space-between, center);
}

.contentWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  figure {
    width: 140px;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2px;
    }
  }
}

.content {
  h4 {
    //Size, Weight, Line Height
    @include _text(16px, 600, 24px);
    margin-bottom: 2px;
    text-transform: uppercase;
  }
  p {
    @include _text(14px, 500, 20px, none, $text);
  }
}

.properties {
  display: flex;
  align-items: center;

  overflow: hidden;
  div {
    //Size, Weight, Line Height
    @include _text(16px, 500, 24px, none);
    width: 80px;
    margin-left: 24px;
    white-space: nowrap;
    @include laptop {
      &:nth-child(n + 5) {
        display: none;
      }
    }
    @include tablet {
      display: none;
    }
  }
}
