@import "../../../../../assets/styles/helpers.scss";

.modalText {
  //Size, Weight, Line Height
  @include _text(32px, 400, 38px);
  color: $black;
  padding-top: 32px;
  padding-bottom: 40px;
  width: 85%;
}

.connectCards {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.close {
  background-color: transparent;
  border: none;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 46px;
  right: 46px;
  cursor: pointer;
}
.button {
  color: var(--white);
  &:hover {
    color: var(--button-hover-text);
    border-color: var(--button-border);
  }
}
