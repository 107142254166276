@import "../../../../assets/styles/helpers.scss";

.element {
  background-color: $bg;
  @include maxWidth {
    padding: 0;
  }
}

.upcomingContainer {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 120px;
}

.title {
  @include center(space-between, center);
  padding: 40px 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.button {
  display: block;
  @include tablet {
    display: none;
  }
}

.buttonMobile {
  display: none;
  margin-top: 40px;
  @include tablet {
    display: block;
  }
}
