@import "../../../../assets/styles/helpers.scss";

.element {
  padding-top: 40px;
  padding-bottom: 120px;
  display: flex;
  gap: 27px;
  @include tablet {
    flex-wrap: wrap;
  }
  max-width: 1440px;
  margin: 0 auto;
  @include maxWidth {
    padding-left: 0;
    padding-right: 0;
  }
  @include phone {
    padding-bottom: 100px;
  }
}
